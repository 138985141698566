import Vue from 'vue'

export default {
	body: null,
	install(Vue, options = {}) {
		this.body = new Vue({
			data() {
				return {
					classList: []
				}
			},
			mounted() {
				this.classList = [].slice.call(document.body.classList)
			},
			methods: {
				hasClass(className) {
					return this.classList.includes(className)
				},
				setClass(className) {
					if(!this.hasClass(className)) {
						this.classList.push(className)
					}
				},
				removeClass(className) {
					if(this.hasClass(className)) {
						let index = this.classList.findIndex(c => c === className)
						this.classList.splice(index, 1)
					}
				}
			},
			watch: {
				classList(v) {
					document.body.classList.remove(...document.body.classList)
					v.forEach(c => document.body.classList.add(c))
				}
			}
		})

		Vue.prototype.$body = this.body
	}
}