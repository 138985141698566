import Vue from 'vue'
import Vuex from 'vuex'

import debug from './modules/debug/index'
import user from './modules/user/index'
import modals from './modules/modals/index'
import lists from './modules/lists/index'
import notViewed from './modules/viewed/index'
import deadEnds from "@/store/modules/deadEnds";

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		debug: debug,
		user: user,
		modals: modals,
		lists: lists,
		notViewed: notViewed,
		deadEnds: deadEnds
	},
})
