export const getUser = (state) => {
	return state.user
}

export const getRole = (state) => {
	if(state.roleId == 1) return 'user'
	if(state.roleId == 2) return 'man'
	if(state.roleId == 3) return 'dispatcher'
	if(state.roleId == 4) return 'kassa'
	if(state.roleId == 7) return 'manager'
}

export const isAuthorized = (state) => Object.keys(state).length