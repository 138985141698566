import log from '@/utils/log'
import { router, rebootRoutes } from '@/router'

export function setUser(ctx, args) {
	log.info('Action user setUser', args)
	ctx.commit('user/setUser', args, { root: true })
	rebootRoutes()
}







