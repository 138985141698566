import log from '@/utils/log'

export function setDeadEnds(ctx, args) {
	log.info('Action deadEnds setDeadEnds', args)
	ctx.commit('deadEnds/setDeadEnds', args, { root: true })
}

// export function addListContractor(ctx, args) {
// 	log.info('Action lists addListContractor', args)
// 	ctx.commit('lists/addListContractor', args, { root: true })
// }
//
// export function editListContractor(ctx, args) {
// 	log.info('Action lists editListContractor', args)
// 	ctx.commit('lists/editListContractor', args, { root: true })
// }
//





