import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import log from '../utils/log'
import store from '../store'
import api from "../api";
import getToken from "../utils/getToken";

Vue.use(VueRouter)

const routes = [
	{
		path: '/test1',
		name: 'test1',
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/test1.vue'),
		meta: {
			access: [ 'all' ]
		}
	},
	{
		path: '/',
		name: 'DashBoard',
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashBoard.vue'),
		meta: {
			access: [ 'all' ]
		}
	},
	{
		path: '/auth',
		name: 'Auth',
		component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue'),
		meta: {
			access: [ 'all' ]
		}
	},
	{
		path: '/verify_contract',
		name: 'CheckDocument',
		component: () => import(/* webpackChunkName: "auth" */ '../views/CheckDocument.vue'),
		meta: {
			access: [ 'all' ]
		}
	},
	{ //TODO не менять ссылку
		path: '/verify_agreement',
		name: 'CheckDocument',
		component: () => import(/* webpackChunkName: "auth" */ '../views/CheckDocument.vue'),
		meta: {
			access: [ 'all' ]
		}
	},
	{
		path: '/registration',
		name: 'Registration',
		component: () => import(/* webpackChunkName: "registration" */ '../views/Registration.vue'),
		meta: {
			access: [ 'all' ]
		}
	},
	{
		path: '/profile/general',
		name: 'ProfileGeneral',
		component: () => import(/* webpackChunkName: "profile-general" */ '../views/profile/General.vue'),
		meta: {
			access: [ 'all' ]
		}
	},
	{
		path: '/profile/contacts',
		name: 'ProfileContacts',
		component: () => import(/* webpackChunkName: "profile-contacts" */ '../views/profile/Contacts.vue'),
		meta: {
			access: [ 'all' ]
		}
	},
	{
		path: '/profile/requisites',
		name: 'ProfileRequisite',
		component: () => import(/* webpackChunkName: "profile-requisites" */ '../views/profile/Requisites.vue'),
		meta: {
			access: [ 'all' ]
		}
	},
	{
		path: '/profile/password',
		name: 'ProfilePassword',
		component: () => import(/* webpackChunkName: "profile-password" */ '../views/profile/Password.vue'),
		meta: {
			access: [ 'all' ]
		}
	},
	//Contr
	{
		path: '/requests/supply/work',
		name: 'RequestSupplyWork',
		component: () => import(/* webpackChunkName: "user-requests-supply-work" */ '../views/user/requests/supply/Work.vue'),
		meta: {
			access: [ 'user' ]
		}
	},
	{
		path: '/requests/supply/done',
		name: 'RequestSupplyDone',
		component: () => import(/* webpackChunkName: "user-requests-supply-done" */ '../views/user/requests/supply/Done.vue'),
		meta: {
			access: [ 'user' ]
		}
	},
	{
		path: '/requests/remove/wagons',
		name: 'RequestRemoveWagons',
		component: () => import(/* webpackChunkName: "user-requests-remove-wagons" */ '../views/user/requests/remove/Wagons.vue'),
		meta: {
			access: [ 'user' ]
		}
	},
	{
		path: '/requests/remove/work',
		name: 'RequestRemoveWork',
		component: () => import(/* webpackChunkName: "user-requests-remove-work" */ '../views/user/requests/remove/Work.vue'),
		meta: {
			access: [ 'user' ]
		}
	},
	{
		path: '/requests/remove/done',
		name: 'RequestRemoveDone',
		component: () => import(/* webpackChunkName: "user-requests-remove-done" */ '../views/user/requests/remove/Done.vue'),
		meta: {
			access: [ 'user' ]
		}
	},
	{
		path: '/requests/maneuver/work',
		name: 'RequestManeuverWork',
		component: () => import(/* webpackChunkName: "contr-requests-maneuver-work" */ '../views/user/requests/maneuver/Work.vue'),
		meta: {
			access: [ 'user' ]
		}
	},
	{
		path: '/requests/maneuver/done',
		name: 'RequestManeuverDone',
		component: () => import(/* webpackChunkName: "contr-requests-maneuver-done" */ '../views/user/requests/maneuver/Done.vue'),
		meta: {
			access: [ 'user' ]
		}
	},
	{
		path: '/instruction',
		name: 'Instruction',
		component: () => import(/* webpackChunkName: "contr-instruction" */ '../views/Instruction.vue'),
		meta: {
			access: [ 'user' ]
		}
	},
	{
		path: '/transfer/wagons',
		name: 'transferWagons',
		component: () => import(/* webpackChunkName: "user-transfer-wagons-index" */ '../views/user/transfer/Wagons'),
		meta: {
			access: [ 'user' ]
		}
	},
	{
		path: '/transfer/history',
		name: 'transferHistory',
		component: () => import(/* webpackChunkName: "user-transfer-history-index" */ '../views/user/transfer/History'),
		meta: {
			access: [ 'user' ]
		}
	},
	{
		path: '/transfer/outgoing',
		name: 'transferOutgoing',
		component: () => import(/* webpackChunkName: "user-transfer-outgoing" */ '../views/user/transfer/Outgoing'),
		meta: {
			access: [ 'user' ]
		}
	},
	{
		path: '/transfer/incoming',
		name: 'transferIncoming',
		component: () => import(/* webpackChunkName: "user-transfer-incoming" */ '../views/user/transfer/Incoming'),
		meta: {
			access: [ 'user' ]
		}
	},
	{
		path: '/location_wagons',
		name: 'LocationWagons',
		component: () => import(/* webpackChunkName: "user-location-wagons-index" */ '../views/locationWagons/Index.vue'),
		meta: {
			access: [ 'user' ]
		}
	},
	{
		path: '/profiles',
		name: 'profilesIndex',
		component: () => import(/* webpackChunkName: "user-profiles-index-outgoing" */ '../views/profiles/Index'),
		meta: {
			access: [ 'user' ]
		}
	},
	{
		path: '/profiles/add',
		name: 'profileAdd',
		component: () => import(/* webpackChunkName: "user-profiles-add-outgoing" */ '../views/profiles/AddEdit'),
		meta: {
			access: [ 'user' ]
		}
	},
	{
		path: '/profiles/:id',
		name: 'profileShow',
		component: () => import(/* webpackChunkName: "user-profiles-show-outgoing" */ '../views/profiles/AddEdit'),
		meta: {
			access: [ 'user' ]
		}
	},
	//Disp
	{
		path: '/requests/supply/new',
		name: 'RequestSupplyNew',
		component: () => import(/* webpackChunkName: "disp-requests-supply-new" */ '../views/disp/requests/supply/New.vue'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	{
		path: '/requests/remove/long',
		name: 'RequestRemoveLong',
		component: () => import(/* webpackChunkName: "disp-requests-remove-long" */ '../views/disp/requests/remove/Long.vue'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	// {
	// 	path: '/requests/remove/long/:page(\page\)?/:currentPage?',
	// 	name: 'RequestRemoveLong',
	// 	component: () => import(/* webpackChunkName: "disp-requests-remove-long" */ '../views/disp/requests/remove/Long.vue'),
	// 	meta: {
	// 		access: [ 'dispatcher' ]
	// 	}
	// },
	{
		path: '/requests/supply/work',
		// redirect: '/requests/supply/work/:currentPage',
		name: 'RequestSupplyWork',
		component: () => import(/* webpackChunkName: "disp-requests-supply-work" */ '../views/disp/requests/supply/Work.vue'),
		props: true,
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	// {
	// 	path: '/requests/supply/work/:page(\page\)?/:currentPage?',
	// 	// redirect: '/requests/supply/work/:currentPage',
	// 	name: 'RequestSupplyWork',
	// 	component: () => import(/* webpackChunkName: "disp-requests-supply-work" */ '../views/disp/requests/supply/Work.vue'),
	// 	meta: {
	// 		access: [ 'dispatcher' ]
	// 	}
	// },
	{
		path: '/requests/supply/long',
		name: 'RequestSupplyLong',
		component: () => import(/* webpackChunkName: "disp-requests-supply-long" */ '../views/disp/requests/supply/Long.vue'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	// {
	// 	path: '/requests/supply/long/:page(\page\)?/:currentPage?',
	// 	name: 'RequestSupplyLong',
	// 	component: () => import(/* webpackChunkName: "disp-requests-supply-long" */ '../views/disp/requests/supply/Long.vue'),
	// 	meta: {
	// 		access: [ 'dispatcher' ]
	// 	}
	// },
	{
		path: '/requests/supply/done/:page(\page\)?/:currentPage?',
		name: 'RequestSupplyDone',
		component: () => import(/* webpackChunkName: "disp-requests-supply-done" */ '../views/disp/requests/supply/Done.vue'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	// {
	// 	path: '/requests/supply/done',
	// 	name: 'RequestSupplyDone',
	// 	component: () => import(/* webpackChunkName: "disp-requests-supply-done" */ '../views/disp/requests/supply/Done.vue'),
	// 	meta: {
	// 		access: [ 'dispatcher' ]
	// 	}
	// },
	{
		path: '/requests/remove/wagons',
		name: 'RequestRemoveWagons',
		component: () => import(/* webpackChunkName: "disp-requests-remove-wagons" */ '../views/disp/requests/remove/Wagons.vue'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	{
		path: '/requests/remove/new',
		name: 'RequestRemoveNew',
		component: () => import(/* webpackChunkName: "disp-requests-remove-new" */ '../views/disp/requests/remove/New.vue'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	{
		path: '/requests/remove/work',
		name: 'RequestRemoveWork',
		component: () => import(/* webpackChunkName: "disp-requests-remove-work" */ '../views/disp/requests/remove/Work.vue'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	// {
	// 	path: '/requests/remove/work/:page(\page\)?/:currentPage?',
	// 	name: 'RequestRemoveWork',
	// 	component: () => import(/* webpackChunkName: "disp-requests-remove-work" */ '../views/disp/requests/remove/Work.vue'),
	// 	meta: {
	// 		access: [ 'dispatcher' ]
	// 	}
	// },
	{
		path: '/requests/remove/done/:page(\page\)?/:currentPage?',
		name: 'RequestRemoveDone',
		component: () => import(/* webpackChunkName: "disp-requests-remove-done" */ '../views/disp/requests/remove/Done.vue'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	// {
	// 	path: '/requests/remove/done',
	// 	name: 'RequestRemoveDone',
	// 	component: () => import(/* webpackChunkName: "disp-requests-remove-done" */ '../views/disp/requests/remove/Done.vue'),
	// 	meta: {
	// 		access: [ 'dispatcher' ]
	// 	}
	// },
	{
		path: '/requests/maneuver/work',
		name: 'RequestManeuverWork',
		component: () => import(/* webpackChunkName: "disp-requests-maneuver-work" */ '../views/disp/requests/maneuver/Work.vue'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	{
		path: '/requests/maneuver/done/:page(\page\)?/:currentPage?',
		name: 'RequestManeuverDone',
		component: () => import(/* webpackChunkName: "disp-requests-remove-done" */ '../views/disp/requests/maneuver/Done.vue'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	// {
	// 	path: '/requests/maneuver/done',
	// 	name: 'RequestManeuverDone',
	// 	component: () => import(/* webpackChunkName: "disp-requests-maneuver-done" */ '../views/disp/requests/maneuver/Done.vue'),
	// 	meta: {
	// 		access: [ 'dispatcher' ]
	// 	}
	// },
	{
		path: '/location_wagons',
		name: 'LocationWagons',
		component: () => import(/* webpackChunkName: "disp-location-wagons-index" */ '../views/locationWagons/Index.vue'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	{
		path: '/natural_list/supply/lists/:page(\page\)?/:currentPage?',
		name: 'NaturalListSupplyList',
		component: () => import(/* webpackChunkName: "disp-natural-list-supply-list" */ '../views/naturalList/supply/List.vue'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	// {
	// 	path: '/natural_list/supply/lists',
	// 	name: 'NaturalListSupplyList',
	// 	component: () => import(/* webpackChunkName: "disp-natural-list-supply-list" */ '../views/naturalList/supply/List.vue'),
	// 	meta: {
	// 		access: [ 'dispatcher' ]
	// 	}
	// },
	{
		path: '/natural_list/supply/wagons',
		name: 'NaturalListSupplyWagons',
		component: () => import(/* webpackChunkName: "disp-natural-list-supply-wagons" */ '../views/naturalList/supply/Wagons.vue'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	{
		path: '/natural_list/remove/wagons',
		name: 'NaturalListRemoveWagons',
		component: () => import(/* webpackChunkName: "disp-natural-list-remove-wagons" */ '../views/naturalList/remove/Wagons.vue'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	{
		path: '/natural_list/remove/lists/:page(\page\)?/:currentPage?',
		name: 'NaturalListRemoveList',
		component: () => import(/* webpackChunkName: "disp-natural-list-remove-list" */ '../views/naturalList/remove/List.vue'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	// {
	// 	path: '/natural_list/remove/lists',
	// 	name: 'NaturalListRemoveList',
	// 	component: () => import(/* webpackChunkName: "disp-natural-list-remove-list" */ '../views/naturalList/remove/List.vue'),
	// 	meta: {
	// 		access: [ 'dispatcher' ]
	// 	}
	// },
	{
		path: '/contractors/:page(\page\)?/:currentPage?',
		name: 'ContractorsIndex',
		component: () => import(/* webpackChunkName: "disp-contractors-index" */ '../views/contractors/Index'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	{
		path: '/carriageTurnover/supply',
		name: 'carriageTurnoverSupply',
		component: () => import(/* webpackChunkName: "disp-carriage-turnover-supply" */ '../views/carriageTurnover/Supply'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	{
		path: '/carriageTurnover/maneuver',
		name: 'carriageTurnoverManeuver',
		component: () => import(/* webpackChunkName: "disp-carriage-turnover-maneuver" */ '../views/carriageTurnover/Maneuver'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	{
		path: '/carriageTurnover/total',
		name: 'carriageTurnoverTotal',
		component: () => import(/* webpackChunkName: "disp-carriage-total-supply" */ '../views/carriageTurnover/Total'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	{
		path: '/duty_plan',
		name: 'dutyPlan',
		component: () => import(/* webpackChunkName: "disp-duty-plan-index" */ '../views/dutyPlan/Index'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	{
		path: '/timeline',
		name: 'timeline',
		component: () => import(/* webpackChunkName: "disp-timeline-index" */ '../views/timeline/Index'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	{
		path: '/destinations',
		name: 'destinations',
		component: () => import(/* webpackChunkName: "disp-destinations-index" */ '../views/destinations/Index'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	{
		path: '/transfer/wagons',
		name: 'transferWagons',
		component: () => import(/* webpackChunkName: "disp-transfer-wagons-index" */ '../views/disp/transfer/Wagons'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	{
		path: '/transfer/history',
		name: 'transferHistory',
		component: () => import(/* webpackChunkName: "disp-transfer-history-index" */ '../views/disp/transfer/History'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	{
		path: '/transfer/incoming',
		name: 'transferIncoming',
		component: () => import(/* webpackChunkName: "disp-transfer-incoming" */ '../views/disp/transfer/Incoming'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	{
		path: '/transfer/outgoing',
		name: 'transferOutgoing',
		component: () => import(/* webpackChunkName: "disp-transfer-outgoing" */ '../views/disp/transfer/Outgoing'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	{
		path: '/profiles',
		name: 'profilesIndex',
		component: () => import(/* webpackChunkName: "disp-profiles-index-outgoing" */ '../views/profiles/Index'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	{
		path: '/profiles/add',
		name: 'profileAdd',
		component: () => import(/* webpackChunkName: "disp-profiles-add-outgoing" */ '../views/profiles/AddEdit'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	{
		path: '/profiles/:id',
		name: 'profileShow',
		component: () => import(/* webpackChunkName: "disp-profiles-show-outgoing" */ '../views/profiles/AddEdit'),
		meta: {
			access: [ 'dispatcher' ]
		}
	},
	//Kassa
	{
		path: '/carriageTurnover/supply',
		name: 'carriageTurnoverSupply',
		component: () => import(/* webpackChunkName: "disp-carriage-turnover-supply" */ '../views/carriageTurnover/Supply'),
		meta: {
			access: [ 'kassa' ]
		}
	},
	{
		path: '/carriageTurnover/maneuver',
		name: 'carriageTurnoverManeuver',
		component: () => import(/* webpackChunkName: "disp-carriage-turnover-maneuver" */ '../views/carriageTurnover/Maneuver'),
		meta: {
			access: [ 'kassa' ]
		}
	},
	{
		path: '/carriageTurnover/total',
		name: 'carriageTurnoverTotal',
		component: () => import(/* webpackChunkName: "disp-carriage-total-supply" */ '../views/carriageTurnover/Total'),
		meta: {
			access: [ 'kassa' ]
		}
	},
	{
		path: '/requests/supply/new',
		name: 'RequestSupplyNew',
		component: () => import(/* webpackChunkName: "kassa-requests-supply-new" */ '../views/kassa/requests/supply/New.vue'),
		meta: {
			access: [ 'kassa' ]
		}
	},
	{
		path: '/requests/supply/work',
		name: 'RequestSupplyWork',
		component: () => import(/* webpackChunkName: "kassa-requests-supply-work" */ '../views/kassa/requests/supply/Work.vue'),
		meta: {
			access: [ 'kassa' ]
		}
	},
	{
		path: '/requests/supply/done',
		name: 'RequestSupplyDone',
		component: () => import(/* webpackChunkName: "kassa-requests-supply-done" */ '../views/kassa/requests/supply/Done.vue'),
		meta: {
			access: [ 'kassa' ]
		}
	},
	{
		path: '/requests/remove/wagons',
		name: 'RequestRemoveWagons',
		component: () => import(/* webpackChunkName: "kassa-requests-remove-wagons" */ '../views/kassa/requests/remove/Wagons.vue'),
		meta: {
			access: [ 'kassa' ]
		}
	},
	{
		path: '/requests/remove/new',
		name: 'RequestRemoveNew',
		component: () => import(/* webpackChunkName: "kassa-requests-remove-new" */ '../views/kassa/requests/remove/New.vue'),
		meta: {
			access: [ 'kassa' ]
		}
	},
	{
		path: '/requests/remove/work',
		name: 'RequestRemoveWork',
		component: () => import(/* webpackChunkName: "kassa-requests-remove-work" */ '../views/kassa/requests/remove/Work.vue'),
		meta: {
			access: [ 'kassa' ]
		}
	},
	{
		path: '/requests/remove/done',
		name: 'RequestRemoveDone',
		component: () => import(/* webpackChunkName: "kassa-requests-remove-done" */ '../views/kassa/requests/remove/Done.vue'),
		meta: {
			access: [ 'kassa' ]
		}
	},
	{
		path: '/requests/maneuver/work',
		name: 'RequestManeuverWork',
		component: () => import(/* webpackChunkName: "kassa-requests-maneuver-work" */ '../views/kassa/requests/maneuver/Work.vue'),
		meta: {
			access: [ 'kassa' ]
		}
	},
	{
		path: '/requests/maneuver/done',
		name: 'RequestManeuverDone',
		component: () => import(/* webpackChunkName: "kassa-requests-maneuver-done" */ '../views/kassa/requests/maneuver/Done.vue'),
		meta: {
			access: [ 'kassa' ]
		}
	},
	{
		path: '/natural_list/supply/lists',
		name: 'NaturalListSupplyList',
		component: () => import(/* webpackChunkName: "disp-natural-list-supply-list" */ '../views/naturalList/supply/List.vue'),
		meta: {
			access: [ 'kassa' ]
		}
	},
	{
		path: '/natural_list/supply/wagons',
		name: 'NaturalListSupplyWagons',
		component: () => import(/* webpackChunkName: "disp-natural-list-supply-wagons" */ '../views/naturalList/supply/Wagons.vue'),
		meta: {
			access: [ 'kassa' ]
		}
	},
	{
		path: '/natural_list/remove/wagons',
		name: 'NaturalListRemoveWagons',
		component: () => import(/* webpackChunkName: "disp-natural-list-remove-wagons" */ '../views/naturalList/remove/Wagons.vue'),
		meta: {
			access: [ 'kassa' ]
		}
	},
	{
		path: '/natural_list/remove/lists',
		name: 'NaturalListRemoveList',
		component: () => import(/* webpackChunkName: "disp-natural-list-remove-list" */ '../views/naturalList/remove/List.vue'),
		meta: {
			access: [ 'kassa' ]
		}
	},
	{
		path: '/contractors',
		name: 'ContractorsIndex',
		component: () => import(/* webpackChunkName: "disp-contractors-index" */ '../views/contractors/Index'),
		meta: {
			access: [ 'kassa' ]
		}
	},
	{
		path: '/location_wagons',
		name: 'LocationWagons',
		component: () => import(/* webpackChunkName: "disp-location-wagons-index" */ '../views/locationWagons/Index.vue'),
		meta: {
			access: [ 'kassa' ]
		}
	},
	{
		path: '/duty_plan',
		name: 'dutyPlan',
		component: () => import(/* webpackChunkName: "disp-duty-plan-index" */ '../views/dutyPlan/Index'),
		meta: {
			access: [ 'kassa' ]
		}
	},
	//Man
	{
		path: '/carriage_maneuver',
		name: 'carriageManeuver',
		component: () => import(/* webpackChunkName: "disp-carriage-maneuver-index" */ '../views/carriageManeuver/Index3.vue'),
		meta: {
			access: [ 'man' ]
		}
	},
	{
		path: '/carriage_maneuver2',
		name: 'carriageManeuver2',
		component: () => import(/* webpackChunkName: "disp-carriage-maneuver2-index" */ '../views/carriageManeuver/Index2.vue'),
		meta: {
			access: [ 'man' ]
		}
	},
	{
		path: '/carriage_maneuver3',
		name: 'carriageManeuver3',
		component: () => import(/* webpackChunkName: "disp-carriage-maneuver2-index" */ '../views/carriageManeuver/Index3.vue'),
		meta: {
			access: [ 'man' ]
		}
	},
	{
		path: '/destinations',
		name: 'destinations',
		component: () => import(/* webpackChunkName: "disp-destinations-index" */ '../views/destinations/Index'),
		meta: {
			access: [ 'man' ]
		}
	},
	{
		path: '/natural_list/supply/lists/:page(\page\)?/:currentPage?',
		name: 'NaturalListSupplyList',
		component: () => import(/* webpackChunkName: "disp-natural-list-supply-list" */ '../views/naturalList/supply/List.vue'),
		meta: {
			access: [ 'man' ]
		}
	},
	{
		path: '/natural_list/supply/wagons',
		name: 'NaturalListSupplyWagons',
		component: () => import(/* webpackChunkName: "disp-natural-list-supply-wagons" */ '../views/naturalList/supply/Wagons.vue'),
		meta: {
			access: [ 'man' ]
		}
	},
	{
		path: '/natural_list/remove/wagons',
		name: 'NaturalListRemoveWagons',
		component: () => import(/* webpackChunkName: "disp-natural-list-remove-wagons" */ '../views/naturalList/remove/Wagons.vue'),
		meta: {
			access: [ 'man' ]
		}
	},
	{
		path: '/natural_list/remove/lists/:page(\page\)?/:currentPage?',
		name: 'NaturalListRemoveList',
		component: () => import(/* webpackChunkName: "disp-natural-list-remove-list" */ '../views/naturalList/remove/List.vue'),
		meta: {
			access: [ 'man' ]
		}
	},
	{
		path: '/duty_plan',
		name: 'dutyPlan',
		component: () => import(/* webpackChunkName: "disp-duty-plan-index" */ '../views/dutyPlan/Index'),
		meta: {
			access: [ 'man' ]
		}
	},
	//Manager
	{
		path: '/contractors/:page(\page\)?/:currentPage?',
		name: 'ContractorsIndex',
		component: () => import(/* webpackChunkName: "disp-manager-index" */ '../views/contractors/Index'),
		meta: {
			access: [ 'manager' ]
		}
	},
	{
		path: '/profiles',
		name: 'profilesIndex',
		component: () => import(/* webpackChunkName: "manager-profiles-index-outgoing" */ '../views/profiles/Index'),
		meta: {
			access: [ 'manager' ]
		}
	},
	{
		path: '/profiles/add',
		name: 'profileAdd',
		component: () => import(/* webpackChunkName: "manager-profiles-add-outgoing" */ '../views/profiles/AddEdit'),
		meta: {
			access: [ 'manager' ]
		}
	},
	{
		path: '/profiles/:id',
		name: 'profileShow',
		component: () => import(/* webpackChunkName: "manager-profiles-show-outgoing" */ '../views/profiles/AddEdit'),
		meta: {
			access: [ 'manager' ]
		}
	},
	{
		path: '/contracts/:page(\page\)?/:currentPage?',
		name: 'contractsIndex',
		component: () => import(/* webpackChunkName: "manager-profiles-index-outgoing" */ '../views/contracts/Index.vue'),
		meta: {
			access: [ 'manager' ]
		}
	},
	{
		path: '/agreements/:page(\page\)?/:currentPage?',
		name: 'agreementIndex',
		component: () => import(/* webpackChunkName: "manager-profiles-index-outgoing" */ '../views/agreements/Index.vue'),
		meta: {
			access: [ 'manager' ]
		}
	},
	{
		path: '/contracts/:id',
		name: 'contractShow',
		component: () => import(/* webpackChunkName: "manager-profiles-show-outgoing" */ '../views/profiles/AddEdit'),
		meta: {
			access: [ 'manager' ]
		}
	},
	{
		path: '/deadEnds',
		name: 'deadEnds',
		component: () => import(/* webpackChunkName: "manager-profiles-show-outgoing" */ '../views/deadEnds/DeadEnds'),
		meta: {
			access: [ 'manager' ]
		}
	},
	{
		path: '/tariffs/:id',
		name: 'TariffShow',
		component: () => import(/* webpackChunkName: "manager-profiles-show-outgoing" */ '../views/deadEnds/Tariffs'),
		meta: {
			access: [ 'manager' ]
		}
	},
]

// const originalPush = VueRouter.prototype.push;
//
// VueRouter.prototype.push = function push(location, onComplete, onAbort) {
// 	const result = originalPush.call(
// 		this,
// 		location,
// 		onComplete,
// 		onAbort
// 	);
// 	if (result) {
// 		return result.catch(err => {
// 			if (err.name !== 'NavigationDuplicated') {
// 				throw err;
// 			}
// 		});
// 	}
// 	return result;
// };
//
// const originalReplace = VueRouter.prototype.replace;
// VueRouter.prototype.replace = function replace(location, onComplete, onAbort) {
// 	const result = originalReplace.call(
// 		this,
// 		location,
// 		onComplete,
// 		onAbort
// 	);
// 	if (result) {
// 		return result.catch(err => {
// 			if (err.name !== 'NavigationDuplicated') {
// 				throw err;
// 			}
// 		});
// 	}
// 	return result;
// };


const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return {x: 0, y: 0}
		}
	},
	routes: routes.filter(r => r.meta.access.indexOf('all') >= 0)
})

router.onReady(() => {
	log.info('router onReady')
})


router.beforeEach(async (to, from, next) => {
	log.info('Router beforeEach')
	NProgress.start();
	console.log(to.path)

	if(to.path !== '/auth' && to.path !== '/verify_contract' && to.path !== '/verify_agreement') {
		if (typeof store.state.user.id == 'undefined') {
			location.href = '/auth?redirect_to=' + location.pathname
			// await api.post('/check', {
			// 	token: getToken()
			// }).then((r) => {
			// 	if (r.data.status == true) {
			// 		store.dispatch('user/setUser', r.data.response.user)
			// 		store.dispatch('lists/setLists', r.data.response.lists)
			// 		next()
			// 	} else {
			// 		location.href = '/auth'
			// 	}
			// })
		} else {
			next()
		}
	} else {
		next()
	}

})

router.afterEach((to, from) => {
	log.info('Router afterEach')
	NProgress.done()
})

export function rebootRoutes() {
	router.addRoutes([ ...routes.filter(r => r.meta.access.indexOf(store.getters['user/getRole']) >= 0), {
		path: '*',
		name: 'Error404',
		component: () => import(/* webpackChunkName: "error-404" */ '../views/Error404.vue')
	}])
}

export default router
