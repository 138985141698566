import config from './../config'
import cookie from './../utils/cookie'
import api from "@/api";

export default {
	info() {
		if(config.debug || cookie.get('debug')) {
			let dateObj = new Date();
			let hour = dateObj.getHours();
			let minute = dateObj.getMinutes();
			let second = dateObj.getSeconds();
			let milliseconds = dateObj.getMilliseconds()

			if (minute < 10) minute = '0' + minute;
			if (second < 10) second = '0' + second;
			if (milliseconds < 100) milliseconds = '0' + milliseconds;
			if (milliseconds < 1000) milliseconds = '0' + milliseconds;

			let out = `%c${hour}:${minute}:${second}:${milliseconds}:%c`;

			console.info(out, "color: #2980b9; font-weight: 600", "color: #333333", ...arguments)
		}
	},
	warn() {
		if(config.debug || cookie.get('debug')) {
			console.warn('Ворн', ...arguments)
		}
	},
	err() {
		if(config.debug || cookie.get('debug')) {
			console.error('Эррор', ...arguments)
		}
	},
	time(name) {
		if(config.debug || cookie.get('debug')) {
			console.time(name)
		}
	},
	timeEnd(name) {
		if(config.debug || cookie.get('debug')) {
			console.timeEnd(name)
		}
	},
	fatal(msg) {
		api.post('/log_telegram', {
			text: msg
		})
		if(config.debug || cookie.get('debug')) {
			console.error('Эррор', ...arguments)
		}
	}
}
