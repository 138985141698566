import config from '@/config'

export default function () {
    let lang = localStorage.getItem('lang')
    if(lang != null) {
	    if(['ru', 'kk', 'en'].indexOf(lang) >= 0) {
		    return lang;
	    }
    }
	localStorage.setItem('lang', 'ru');
	return 'ru'
}