import Vue from 'vue'
import log from '@/utils/log'

export default {
	setUser(state, args) {
		log.info('Mutation user setUser', args)
		for(let key in args) {
			Vue.set(state, key, args[key])
		}

		let isDispatcher = (user) => user.roleId == 3

		let isUser = (user) => user.roleId == 1

		let isKassa = (user) => user.roleId == 4

		let isMan = (user) => user.roleId == 2

		let iaManager = (user) => user.roleId == 7

		Vue.set(state, 'isUser', isUser(args))
		Vue.set(state, 'isDispatcher', isDispatcher(args))
		Vue.set(state, 'isKassa', isKassa(args))
		Vue.set(state, 'isMan', isMan(args))
		Vue.set(state, 'isManager', iaManager(args))
	},
}