import config from '@/config'

export default function () {
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    let token = params.token || localStorage.getItem(config.tokenName)
    if(token == null) {
        localStorage.setItem(config.tokenName, null);
        return null;
    }
    return token;
}