import axios from 'axios'
import config from "../config";
import log from "../utils/log";
import getToken from '../utils/getToken'
import Vue from 'vue'
import store from '../store'

const api = axios.create({
	baseURL: config.mainUrl,
	timeout: 100000,
	headers: {
		'Content-Type': 'application/json',
		'X-Auth': getToken()
	}
})

api.interceptors.request.use(request => {
	log.info('Отправлен запрос ' + request.url, request)
	return request
})

api.interceptors.response.use(response => {
	log.info('Принят ответ ' + response.config.url, response)
	if(response.data.status == false) {
		if(typeof response.data.error != 'undefined') {
			if(typeof response.data.error.code != 'undefined') {
				if(response.data.error.code === 'unauthorized') {
					location.href = '/auth?redirect_to=' + location.pathname
					return
				}
				if(response.data.error.code === 'not_offer') {
					// Vue.$toast.open({
					// 	message: response.data.error.msg,
					// 	type: 'error',
					// 	duration: 3000,
					// })
					store.dispatch('modals/open', 'offer')
					return
				}
				if(response.data.error.code === 'server_error') {
					// Vue.$toast.open({
					// 	message: response.data.error.msg,
					// 	type: 'error',
					// 	duration: 3000,
					// })
				}
				if(process.env.NODE_ENV === 'development' && response.data.error.code === 'server_error') {
					// console.log(getTelegramMessage(response))
					// axios.post('https://api.telegram.org/bot2118129990:AAEoMm2RcuGE-xAfUnFCty9WZ26zDuhkyI4/sendMessage', {
					// 	chat_id: -1001541025282,
					// 	text: getTelegramMessage(response)
					// })
				}
			}
		}
	}
	return response
}, err => {
	// Vue.$toast.open({
	// 	message: 'Непредвиденная ошибка API',
	// 	type: 'error',
	// 	duration: 3000,
	// })
	if(process.env.NODE_ENV === 'development') {
		// axios.post('https://api.telegram.org/bot2118129990:AAEoMm2RcuGE-xAfUnFCty9WZ26zDuhkyI4/sendMessage', {
		// 	chat_id: -1001541025282,
		// 	text: 'Непредвиденная ошибка API',
		// 	parse_mode: 'HTML'

		// })
	}
	throw err;
})

let getTelegramMessage = (response) => {
	let url = response.config.url
	let status = response.status
	let user = Object.keys(store.state.user).map(i => i + ': ' + store.state.user[i])
	let error = response.data.error.msg

	let rows = [
		`Текст ошибки: ${error}`,
		`Rest: ${url}`,
		`Статус ответа: ${status}`,
		`------------------`,
	]

	return [ ...rows, ...user].join('\n')
}

export default api
