import Vue from 'vue'
import App from './App.vue'
//import './registerServiceWorker'
import store from './store'
import router from './router'
import config from '@/config'
import i18n from './i18n'
import VueMeta from 'vue-meta'
import vOutsideEvents from 'vue-outside-events'
import axios from 'axios'
import log from './utils/log'
import api from './api'
import getToken from "./utils/getToken";
import VueSimpleAlert from "vue-simple-alert";
import VueSweetalert2 from 'vue-sweetalert2';
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueToast from 'vue-toast-notification';
import VueTippy, { TippyComponent } from "vue-tippy";
import NCALayer from "@/utils/NCALayer";
import body from "@/utils/body";
import VueYandexMetrika from 'vue-yandex-metrika'


import VueSocketIO from 'vue-socket.io'
import NProgress from "nprogress";


import JwPagination from 'jw-vue-pagination';
Vue.component('jw-pagination', JwPagination);

import 'vue-toast-notification/dist/theme-default.css';

const EventBus = new Vue()

Object.defineProperties(Vue.prototype, {
	$bus: {
		get: function () {
			return EventBus
		}
	}
})

Vue.config.productionTip = false

Vue.prototype.$config = config
Vue.prototype.$api = api
Vue.prototype.$log = log

Vue.use(VueYandexMetrika, {
	id: 87176911,
	router: router,
	env: process.env.NODE_ENV,
	debug: true
	// other options
})

Vue.use(VueToast)
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.use(new VueSocketIO({
	debug: true,
	secure: true,
	connection: config.socketUrl+'?token='+getToken(),
	vuex: {
		store,
		actionPrefix: "SOCKET_",
		options: {
			useConnectionNamespace: true
		}
	},
	options: {
		// TODO timeout
		//timeout: '186a0'
	}
}))

Vue.use(VueMeta)
Vue.use(vOutsideEvents)
Vue.use(VueSimpleAlert)
Vue.use(VueSweetalert2)

Vue.use(VueTippy, {
	directive: "tippy", // => v-tippy
	flipDuration: 0,
	popperOptions: {
		modifiers: {
			preventOverflow: {
				enabled: false
			}
		}
	}
});
Vue.use(NCALayer)
Vue.use(body)
try {
	caches.keys().then(function(cacheNames) {
		Promise.all(
			cacheNames.map(function(cacheName) {
				console.log(cacheName)
				return caches.delete(cacheName);
			})
		).then(r => {
			//location.reload(true)
		});
	})
} catch(e) {

}

(async function () {
	await axios.post(config.mainUrl + '/check', {
		token: getToken(),
	}, {
		headers: {
			'Content-Type': 'application/json',
			'X-Auth': getToken()
		}
	}).then((r) => {
		if(r.data.status == true) {
			store.dispatch('notViewed/setNotViewed', r.data.response.notViewed)
			store.dispatch('user/setUser', r.data.response.user)
			store.dispatch('lists/setLists', r.data.response.lists)
		}

		new Vue({
			router,
			store,
			i18n,
			render: h => h(App)
		}).$mount('#app')
	})
})()

