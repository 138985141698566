import Vue from 'vue'
import log from '@/utils/log'
import {increment} from "@/store/modules/viewed/actions";

export default {
	setNotViewed(state, args) {
		for(let key in args) {
			Vue.set(state, key, args[key])
		}
		// state.notViewed = args
	},
	decrement(state, args) {
		//supply: 63, remove: 2, maneuver
		if(args === 'supply') state.supply = state.supply - 1;
		if(args === 'remove') state.remove = state.remove - 1;
		if(args === 'maneuver') state.maneuver = state.maneuver - 1;
	},
	increment(state, args) {
		//supply: 63, remove: 2, maneuver
		if(args === 'supply') state.supply = state.supply + 1;
		if(args === 'remove') state.remove = state.remove + 1;
		if(args === 'maneuver') state.maneuver = state.maneuver + 1;
	}

	// setNotViewed(state, args) {
	// 	let notViewed = (notViewed ) => notViewed
	// 	for(let key in args) {
	// 		Vue.set(state, key, args[key])
	// 	}
	//
	// 	log.info('Mutation notViewed setNotViewed', args)
	// 	Vue.set(state, 'notViewed', notViewed(args))
	// },
}