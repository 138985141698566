import log from '@/utils/log'
import { router, rebootRoutes } from '@/router'

export function setNotViewed(ctx, args) {
	log.info('Action viewed setNotViewed', args)
	ctx.commit('notViewed/setNotViewed', args, { root: true })
	//rebootRoutes()
}

export function decrement(ctx, args) {
	log.info('Action viewed decrement', args)
	ctx.commit('notViewed/decrement', args, { root: true })
}

export function increment(ctx, args) {
	log.info('Action viewed increment', args)
	ctx.commit('notViewed/increment', args, { root: true })
}







