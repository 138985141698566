import log from '@/utils/log'

export function setState(ctx, args) {
	log.info('Action debug setState', args)
	ctx.commit('debug/setState', args, {root: true})
}







