import log from '@/utils/log'

export function setUser(ctx, args) {
	log.info('Action user setUser', args)
	ctx.commit('user/setUser', args, { root: true })
}

export function open(ctx, args) {
	log.info('Action modal open', args)
	ctx.commit('modals/open', args, { root: true })
}
export function close(ctx, args) {
	log.info('Action modal close', args)
	ctx.commit('modals/close', args, { root: true })
}

export function setData(ctx, args) {
	log.info('Action modal setData', args)
	ctx.commit('modals/setData', args, { root: true })
}






