import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

export default {
    namespaced: true,
    state: {
		addTariff: {
			show: false,
			data: {},
			params: {},
			componentName: 'AddTariffModal'
		},
		addDeadEnd: {
			show: false,
			data: {},
			params: {},
			componentName: 'AddDeadEndModal'
		},
		addContact: {
			show: false,
			data: {},
			params: {},
			componentName: 'AddContactModal'
		},
	    addRequisite: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'AddRequisiteModal'
	    },
	    addSupply: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'AddSupplyModal'
	    },
	    addSupplyDispatcher: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'AddSupplyDispatcherModal'
	    },
	    editSupplyDispatcher: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'EditSupplyDispatcherModal'
	    },
	    addRemove: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'AddRemoveModal'
	    },
	    addRemoveDispatcher: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'AddRemoveDispatcherModal'
	    },
	    editRemoveDispatcher: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'EditRemoveDispatcherModal'
	    },
	    addManeuver: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'AddManeuverModal'
	    },
		addManeuverDispatcher: {
			show: false,
			data: {},
			params: {},
			componentName: 'AddManeuverDispatcherModal'
		},
		doneManeuverDispatcher: {
			show: false,
			data: {},
			params: {},
			componentName: 'DoneManeuverDispatcherModal'
		},
	    editManeuverDispatcher: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'EditManeuverDispatcherModal'
	    },
		addNaturalList: {
			show: false,
			data: {},
			params: {},
			componentName: 'AddNaturalListModal'
		},
		editNaturalList: {
			show: false,
			data: {},
			params: {},
			componentName: 'EditNaturalListModal'
		},
		addContractor: {
			show: false,
			data: {},
			params: {},
			componentName: 'AddContractorModal'
		},
		editContractor: {
			show: false,
			data: {},
			params: {},
			componentName: 'EditContractorModal'
		},
	    addNaturalListRemove: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'AddNaturalListRemoveModal'
	    },
		editNaturalListRemove: {
			show: false,
			data: {},
			params: {},
			componentName: 'EditNaturalListRemoveModal'
		},
	    getDutyPlanContractors: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'GetDutyPlanContractorsModal'
	    },
	    instructionVideo: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'InstructionVideoModal'
	    },
	    addDestination: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'AddDestinationModal'
	    },
	    editDestination: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'EditDestinationModal'
	    },
	    moveManeuverWagon: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'MoveManeuverWagonModal'
	    },
	    moveManeuverWagonInfo: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'MoveManeuverWagonInfoModal'
	    },
	    createTransfer: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'CreateTransferModal'
	    },
	    approveTransfer: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'ApproveTransferModal'
	    },
	    offer: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'OfferModal'
	    },
	    addEditRequisite: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'AddEditRequisiteModal'
	    },
	    addEditContact: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'AddEditContactModal'
	    },
	    addEditAgreement: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'AddEditAgreementModal'
	    },
	    addEditContract: {
		    show: false,
		    data: {},
		    params: {},
		    componentName: 'AddEditContractModal'
	    },
		tariffsModal:{
			show: false,
			data: {},
			params: {},
			componentName: 'TariffsModal'
		},
		addEditAgreements: {
			show: false,
			data: {},
			params: {},
			componentName: 'AddEditAgreements'
		}
    },
    getters: getters,
    mutations: mutations,
    actions: actions
}