import Vue from 'vue'
import log from '@/utils/log'
import body from "@/utils/body";

export default {
	setUser(state, args) {
		log.info('Mutation user setUser', args)
		for(let key in args) {
			Vue.set(state, key, args[key])
		}
	},
	open(state, args) {
		log.info('Mutation modals open', args)
		//let body = document.body;
		//body.classList.add('modal-open')
		body.body.setClass('modal-open')
		state[args].show = true
	},
	close(state, args) {
		log.info('Mutation modals open', args)
		//let body = document.body;
		//body.classList.remove('modal-open')
		body.body.removeClass('modal-open')
		state[args].show = false
		state[args].data = {}
		state[args].params = {}
	},
	setData(state, args) {
		log.info('Mutation modals setData', args)
		state[args.modal].data = args.data
		if(args.hasOwnProperty('params')) {
			state[args.modal].params = args.params
		}
	}
}
