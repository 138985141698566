import Vue from 'vue'
import log from '@/utils/log'

export default {
	setDeadEnds(state, args) {
		log.info('Mutation deadEnds setDeadEnds', args)
		for(let key in args) {
			Vue.set(state, key, args[key])
		}
	},
	// addListContractor(state, args) {
	// 	log.info('Mutation lists addListContractor', args)
	// 	state.contractors.push(args)
	// },
	// editListContractor(state, args) {
	// 	log.info('Mutation lists editListContractor', args)
	// 	let contractor = state.contractors.find(c => c.id == args.id)
	// 	contractor.bin = args.bin
	// 	contractor.name = args.name
	// 	contractor.deadEnds = args.deadEnds
	// }
}
