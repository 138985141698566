import Vue from 'vue'

export default {
	install(Vue, options = {}) {
		let instance = new Vue({
			data() {
				return {
					isConnected: false,
					isConnection: false,
					ws: null
				}
			},
			methods: {
				send(request) {
					if (!this.ws) {
						throw new Error('Подключение к NCALayer не установлено.');
					}
					const jsonRequest = JSON.stringify(request);
					this.ws.send(jsonRequest);
				},
				setHandlers(resolve, reject) {
					this.ws.onerror = () => {
						reject(new Error('Ошибка взаимодействия с NCALayer. В том случае, если на вашем компьютере не установлен NCALayer, пожалуйста установите его c портала НУЦ РК (https://pki.gov.kz/ncalayer/). Если же NCALayer установлен, но портал выдает ошибку, свяжитесь, пожалуйста, с нашей технической поддержкой.'));
					};

					this.ws.onclose = () => {
						reject(new Error('NCALayer закрыл соединение.'));
					};

					this.ws.onmessage = (msg) => {
						const response = JSON.parse(msg.data);

						if (response.code !== '200') {
							reject(new Error(`${response.code}: ${response.message}`));
							return;
						}
						resolve(response.responseObject);
					};
				},

				async signXml(storageType, xml, keyType = 'SIGNATURE', tbsElementXPath = '', signatureParentElementXPath = '') {
					const request = {
						module: 'kz.gov.pki.knca.commonUtils',
						method: 'signXml',
						args: [
							storageType,
							keyType,
							xml,
							tbsElementXPath,
							signatureParentElementXPath,
						],
					};

					this.send(request);

					return new Promise((resolve, reject) => {
						this.setHandlers(resolve, reject);
					});
				}
			}
		})

		let init = () => {
			connect().catch((e) => {
				setTimeout(init(), 2000)
			})
		}

		let connect = () => {
			if (instance.$data.ws) return
			return new Promise((resolve, reject) => {
				try {
					instance.$data.ws = new WebSocket('wss://127.0.0.1:13579');
					instance.$data.isConnection = true
					instance.$data.ws.onopen = function () {
						instance.$data.isConnected = true
						resolve(this.ws);
					};
					instance.$data.ws.onerror = function (err) {
						instance.$data.isConnection = false
						instance.$data.isConnected = false
						instance.$data.ws = null
						reject(err);
					};
					instance.$data.ws.onclose = function () {
						instance.$data.isConnection = false
						instance.$data.isConnected = false
						instance.$data.ws = null
						reject();
					}
				} catch (e) {
					console.log(e)
				}
			});
		}

		init()

		Vue.prototype.$nca = instance
	}
}