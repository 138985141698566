import log from '@/utils/log'

export function setLists(ctx, args) {
	log.info('Action lists setLists', args)
	ctx.commit('lists/setLists', args, { root: true })
}

export function addListContractor(ctx, args) {
	log.info('Action lists addListContractor', args)
	ctx.commit('lists/addListContractor', args, { root: true })
}

export function editListContractor(ctx, args) {
	log.info('Action lists editListContractor', args)
	ctx.commit('lists/editListContractor', args, { root: true })
}






