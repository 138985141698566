<template>
	<div id="app">
		<router-view name="default"/>

		<template v-for="(modal, ind) in loadedModals">
			<transition name="fade-transition" mode="out-in">
				<component :is="modal" v-if="modals[ind].show" />
			</transition>
		</template>

		<div class="node-env-warning" v-if="type != null && type != 'local'">
			{{ type }}
		</div>
		<div class="only-localhost" v-if="onlyLocalhost">
			Извините, доступ вне localhost временно закрыт
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import config from "@/config";
import {isAuthorized} from "@/store/modules/user/getters";

export default {
	name: "App",
	metaInfo() {
		return {
			titleTemplate: this.titleTemp + '%s'
		}
	},
	data() {
		return {
			loadedModals: {},
			type: null,
			titleTemp: '',
			onlyLocalhost: false
		}
	},
	computed: {
		...mapState({
			user: state => state.user,
			modals: state => state.modals,
			notViewed: state => state.notViewed,
		}),
		...mapGetters({
			isAuthorized: 'user/isAuthorized'
		})
	},
	created() {
		this.$log.info('init App')
		this.loadModals()
	},
	mounted() {
		if(process.env.NODE_ENV == 'development') {
			if(config.mainUrl.search(/cb/) >= 0) {
				this.type = 'prod'
				this.titleTemp = '(prod) '
			}
			if(config.mainUrl.search(/dev/) >= 0) {
				this.type = 'dev'
				this.titleTemp = '(dev) '
			}
			if(config.mainUrl.search(/192.168/) >= 0) {
				let name = localStorage.getItem('name')
				if(name != null) {
					this.titleTemp = '(' + name + ' - local)'
				}else{
					this.titleTemp = '(local) '
				}
				this.type = 'local'
			}
			if(config.onlyLocalhost && location.hostname != 'localhost') {
				this.onlyLocalhost = true
			}
		}
		if(!this.user.offer && this.isAuthorized && this.user.isUser) {
			this.$body.setClass('offer-body')
			this.openModal('offer')
		}
		// setTimeout(() => {
		// 	this.$nca.signXml('PKCS12', '<xml><root></root></xml>').then(r => {
		// 		console.log(r)
		// 	})
		// }, 5000)
	},
	methods: {
		...mapActions({
			'openModal': 'modals/open',
		}),
		loadModals() {
			for(let key in this.modals) {
				let modal = this.modals[key]
				this.loadedModals[key] = () => import(`./components/modals/${modal.componentName}.vue`)
			}
		}
	}
}
</script>

<style lang="scss">
	@import "scss/tabler";

	.node-env-warning {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 11111111111111111;
		font-size: 60px;
		text-align: center;
		font-weight: bold;
		color: #f00;
		pointer-events: none;
		padding: 10px 0;
	}

	.only-localhost {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1000000;
		background: rgba(0,0,0,.5);
		text-align: center;
		line-height: 100vh;
		color: #f00;
		font-size: 30px;
		font-weight: bold;
	}

	.offer-body {
		overflow: hidden;
	}

	.offer-page {
		filter: blur(2px);
	}
</style>
